import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { MONTHS_PER_YEAR, WEEKS_PER_MONTH, DAYS_PER_WEEK, DAYS_PER_MONTH, DAYS_PER_YEAR } from '../helpers/calendar.helper';


import { WithLayout } from '../components';

import '../app.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  monthHeader: {
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid rgba(0, 0, 0, 0.12);`
  }
}))




const calendar = [];

function Calendar() {

  const [currentYear, setCurrentYear] = useState(5);
  const classes = useStyles();

  return (
    <WithLayout>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant={'h5'} color={'primary'}>Calendar By Month</Typography>
        </Grid>
        <Grid item xs={12}>
          <h4>Current Year: <input onChange={(e) => { setCurrentYear(e.target.value) }} type={'text'} value={currentYear} /></h4>
        </Grid>
        <Grid item xs={12} container>
          <MonthsView />
        </Grid>
      </Grid>
    </WithLayout>
  );
}

function WeekdayHeader() {
  const items = [];
  for (let i = 1; i < DAYS_PER_WEEK + 1; i++) {
    items.push(
      <>
        <span>Day{i}</span>
      </>
    )
  }

  return (
    <>{items}</>
  )
}

function MonthsView() {
  const items = [];
  const [dayHovered, setDayHovered] = useState(false);
  const classes = useStyles();

  let dayOfYear = 0;

  for (let i = 1; i < MONTHS_PER_YEAR + 1; i++) {
    let dayOfMonth = 0;
    items.push(
      <Grid item xs={12}>
        <Paper className={classes.paper} variant={'outlined'}>
          <Typography color={'primary'} align={'left'} className={classes.monthHeader}>Month: {i}</Typography>
          <main className={'calendar'}>
            <section className={'th'}>
              <WeekdayHeader />
            </section>
            {Array.from({ length: WEEKS_PER_MONTH }, (x, i) => i).map((w, z) => (
              <div key={z + w} className={'week'}>
                {Array.from({ length: DAYS_PER_WEEK }, (x, i) => i).map((d, xx) => {
                  dayOfMonth++;
                  dayOfYear++;
                  return (
                    <DayView dayOfMonth={xx + 1} dayOfYear={dayOfYear} />
                  )
                })}
              </div>
            ))}
          </main>
        </Paper>
      </Grid>
    );
  }

  return (
    <div style={{ width: 775 }}>
      {items}
    </div>
  )
}

function DayView({ dayOfMonth, dayOfYear }) {
  let season = 'none';

  if (dayOfYear < 82) {
    season = 'winter'
  }

  if (dayOfYear > 81 && dayOfYear < 163) {
    season = 'spring'
  }

  if (dayOfYear > 162 && dayOfYear < 244) {
    season = 'summer'
  }

  if (dayOfYear > 243 && dayOfYear < 325) {
    season = 'fall'
  }

  return (
    <div className={season} data-date={dayOfMonth}>
      {/* <p className={'topODay'}>{dayOfYear}</p> */}
    </div>
  )
}

export default Calendar;
