import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { WithLayout } from '../components';
import { MONTHS_PER_YEAR, WEEKS_PER_MONTH, DAYS_PER_WEEK, DAYS_PER_MONTH, DAYS_PER_YEAR } from '../helpers/calendar.helper';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2)
  }
}));

const listing = [
  `There are ${MONTHS_PER_YEAR} months per year.`,
  `There are ${WEEKS_PER_MONTH} weeks per month.`,
  `There are ${DAYS_PER_WEEK} days per week.`,
  `There are ${DAYS_PER_MONTH} days per month.`,
  `There are ${DAYS_PER_YEAR} days per year.`
];

const About = () => {
  const classes = useStyles();

  return (
    <WithLayout>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper} variant={'outlined'}>
              <Typography variant={'h5'} color={'primary'}>Custom Calendar</Typography>
              <ul>
                {listing.map((t, i) => (
                  <li key={i}>{t}</li>
                ))}
              </ul>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </WithLayout>
  )
}

export default About;