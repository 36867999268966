import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  gridContainer: {
    minHeight: '100vh',
    background: theme.palette.background.default
  }
}))

const Login = ({ setLoggedIn }) => {
  const classes = useStyles();

  
  return (
    <Grid container alignItems={'center'} justify={'center'} className={classes.gridContainer}>
      <Grid item xs={12} md={6}>
        <Paper className={classes.paper} variant={'outlined'}>
          <Button onClick={() => { setLoggedIn(true); }} variant={'contained'} color={'primary'}>Log In</Button>
        </Paper>
      </Grid>
    </Grid>
  )
}
export default Login;