import {
  Container, AppBar, Toolbar, Typography, Button, Grid, Paper
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  '&global': {

  },
  logoSmall: {
    verticalAlign: 'text-top',
    fontSize: 12,
    borderBottom: '1px solid #F8F9FE',
  },
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  appBar: {
    marginBottom: theme.spacing(2)
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(2)
  },
  toolbarTitle: {
    flexGrow: 1,
    fontWeight: 'bolder'
  }
}))

const Header = () => {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.toolbarTitle}>
          <Button component={Link} to={"/"} color="inherit">
            <Typography variant={'h5'}>
              TJ's Calendar <small className={classes.logoSmall}>Beta</small>
            </Typography>
          </Button>
        </div>
        <nav>
          <Button component={Link} to={"/"} color="inherit">Home</Button>
          <Button component={Link} to={"/about"} color="inherit">About</Button>
        </nav>
      </Toolbar>
    </AppBar>
  )
}

const WithLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Header />
      <Container component="section">
        {children}
      </Container>
    </>
  );
}

export default WithLayout;