import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { Calendar, About, Login } from './controllers';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0c3694'
    },
    background: {
      default: "#f8f9FE"
    }
  },
  typography: {
    fontFamily: [
      "Gotham",
      "sans-serif"
    ]
  },
})

const App = () => {

  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <CssBaseline />
          <Switch>
            <Route exact path="/" render={ loggedIn ? (props) => <Calendar {...props} /> : (props) => <Login {...props} setLoggedIn={setLoggedIn} /> } /> 
            <Route exact path="/about" render={ loggedIn ? (props) => <About {...props} /> : (props) => <Login {...props} setLoggedIn={setLoggedIn} /> } />
            <Route exact path="/login" render={(props) => <Login {...props} setLoggedIn={setLoggedIn} /> } />
          </Switch>
        </Router>
      </ThemeProvider>
    </>
  )

}

export default App;
